.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tiptap.ProseMirror {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}

button {
  background-color: #258028;
  border-color: white;
  color: white;
  padding: 4px 24px;
  text-align: center;
  /* text-decoration: none; */
  display: inline-block;
  font-size: 16px;
}
button.is-active {
  background-color: #98c7a8 !important;
  border-color: white;
  color: white;
}
